import React, { useContext } from "react";
import classNames from "classnames";

import "./game-navigate-start.scss";

import { LangContext } from "../../../../App";
import OutlinedButton from "../../../ui/OutlinedButton";

function StartScreen({ className = "", onClick }) {
  const i18n = useContext(LangContext);

  return (
    <div className={classNames(className, "game-navigate-start")}>
      <div className="game-navigate-start__wrapper">
        <div className="game-navigate-start__title">
          <span className="game-navigate-start__font font--text">
            {i18n.t("navigatemedia:introTitle")}
          </span>
        </div>

        <br />
        <br />

        <div className="game-navigate-start__text">
          <span className="game-navigate-start__font font--text">
            {i18n.t("navigatemedia:introText")}
          </span>
        </div>
      </div>

      <OutlinedButton className="game-navigate-start__button" onClick={onClick}>
        {i18n.t("common:Buttons.start")}
      </OutlinedButton>
    </div>
  );
}

export default StartScreen;
