import React, { useContext } from "react";
import classNames from "classnames";

import "./thank-you.scss";

import OutlinedButton from "../../components/ui/OutlinedButton";
import { LangContext } from "../../App";
import routes from "../../constants/routes";

function ThankYou({ className = "" }) {
  const i18n = useContext(LangContext);
  const localesList = ["en", "uk"];
  const redirectRoute = localesList.includes(i18n.language)
    ? routes.MAIN
    : routes.MAIN + i18n.language;

  return (
    <div className={classNames(className, "thank-you")}>
      <div className="thank-you__wrapper">
        <img className="thank-you__image" src="/images/mark.png" alt="mark" />

        <div className="thank-you__title">
          <span className="thank-you__font thank-you__font--title font--title">
            {i18n.t("common:ThankYou")}
          </span>
        </div>

        <div className="thank-you__text">
          <span className="thank-you__font thank-you__font--text font--text">
            {i18n.t("common:LevelImproved")}
          </span>
        </div>
      </div>

      <OutlinedButton
        className="thank-you__button"
        href={redirectRoute}
        color="pink"
      >
        {i18n.t("common:ToMain")}
      </OutlinedButton>
    </div>
  );
}

export default ThankYou;
